body {
  -webkit-tap-highlight-color: rgba(15, 23, 42, 0.1);
}

.bg-cta {
  background-image: linear-gradient(116.54deg, #ff4a69 -3.83%, #ff8c49 62.41%);
}

.ring-primary-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 111 145/var(--tw-ring-opacity));
}

.ring-opacity-40 {
  --tw-ring-opacity: 0.4;
}

.bg-linear-primary-3-1 {
  background-image: linear-gradient(225deg,rgba(255,199,95,.1),rgba(255,111,145,.1));
}


.primary-outline-button {
  position: relative;
  border-radius: .5rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(255 111 145/var(--tw-border-opacity));
  background-image: linear-gradient(116.54deg,#ff4a69 -3.83%,#ff8c49 62.41%);
  -webkit-background-clip: text;
  background-clip: text;
  padding: 15px 1.5rem;
  font-size: 14px;
  line-height: 15px;
  font-weight: 700;
  color: transparent;
  --tw-ring-color: rgb(255 111 145/var(--tw-ring-opacity));
  --tw-ring-opacity: 0.4;
  transition-duration: .15s;
  }



.tooltip {
  position: relative;
  cursor: pointer;
}

.tooltip-content {
  position: absolute;
  top: -1rem;
  transform: translate(-2rem, -100%);
  width: 150px;
  background-color: #555;
  color: white;
  opacity: 0;
  visibility: hidden;
  font-size: 12px;
  line-height: 125%;
  padding: 0.5rem;
  border-radius: 1rem;
}
.tooltip-content::after {
  content: "";
  position: absolute;
  left: 2rem;
  bottom: -1rem;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-color: #555 transparent transparent transparent;
}
.tooltip:hover .tooltip-content {
  opacity: 1;
  visibility: visible;
}

.paywall::before {
    background: linear-gradient(0deg,#fff,hsla(0,0%,100%,0));
    /* background: black; */
    content: "";
    height: 90px;
    left: auto;
    pointer-events: none;
    position: absolute;
    right: auto;
    top: -93px;
    width: 110%;
}

/* .paywall::before {
  content: "hiho"
} */