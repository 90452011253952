@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "INTER";
  src: url("../public/assets/fonts/Inter-Regular.ttf");
}

* {
  scroll-behavior: smooth;
}

.router-transition-enter {
  transition: opacity 300ms cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0;
}

.router-transition-enter.router-transition-enter-active {
  opacity: 1;
}

.router-transition-exit {
  transition: opacity 300ms cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  z-index: 1;
}

.router-transition-exit.router-transition-exit-active {
  opacity: 0;
}

.fade-enter {
  transition: opacity 500ms cubic-bezier(.4, 0, .2, 1);
  z-index: 1;
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
}

.fade-exit {
  transition: opacity 500ms cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  z-index: -1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
}

/*

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*/

/* landingpage */

video#bgvideo {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  background: url(phttps://images.pexels.com/photos/1398003/pexels-photo-1398003.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2) no-repeat;
  background-size: cover;
}

.shake-animation {
  animation: 5s ease-in-out 0s infinite normal none running shake;
}

@keyframes shake {

  82%,
  98% {
    transform: translate3d(-1px, 0, 0);
  }

  84%,
  96% {
    transform: translate3d(2px, 0, 0);
  }

  86%,
  90%,
  94% {
    transform: translate3d(-4px, 0, 0);
  }

  88%,
  92% {
    transform: translate3d(4px, 0, 0);
  }
}

.floating-animation {
  animation: 18s ease-in-out 0s infinite alternate none running floating;
}

button:disabled {
  opacity: 50%;
}

@keyframes floating {
  0%,
  100% {
    -webkit-transform: translate(0, 0);
  }

  65% {
    -webkit-transform: translate(0, 50px);
  }
}

@keyframes pulse {
  0%,
  20% {
    transform: scale(1);
  }

  80%,
  100% {
    transform: scale(1.05);
  }
}

.visable {
  opacity: 100%;
}

.unvisible {
  opacity: 0%;
}